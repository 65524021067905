import React, { useContext, useEffect, useState } from 'react'
import * as Yup from 'yup';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { AuthContext } from '../../contexts/authContext';
import { getUser } from '../../graphql/queries';
import { toast } from 'react-toastify';
import { listSchoolUsersWithSchools } from '../../customGraphql/queries'


const Login = () => {
    const { user, handleUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [passwordType, setPasswordType] = useState('password');
    const [responseError, setResponseError] = useState('');
    const [cognitoData, setCognitoData] = useState('');

    useEffect(() => {
        // updatePassword()
        checkUser()
    }, [user?.id])


    const checkUser = async () => {
        if (!user?.role) {
            let role = await getUserGroup()
            if (role === 'ADMIN') {
                handleUser({ 'role': role })
                navigate('/dashboard')
            } else if (role === 'SCHOOL_ADMIN') {
                handleUser({ 'role': role })
                user?.id && handleGetSchools(user?.id)
            }
            else {
                // toast.error('Access Denied')
            }
        }
    }

    // const updatePassword = () => {
    //     Auth.signIn('vineeth@Neetable.com', '1234@Neetable')
    //         .then((user) => {
    //             if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
    //                 const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
    //                 Auth.completeNewPassword(
    //                     user, // the Cognito User Object
    //                     '1234@Neetable', // the new password
    //                     {
    //                         given_name: 'Vineeth',
    //                         family_name: 'Kumar'

    //                     }
    //                 )
    //                     .then((user) => {
    //                         // at this time the user is logged in if no MFA required
    //                         console.log(user);
    //                     })
    //                     .catch((e) => {
    //                         console.log(e);
    //                     });
    //             } else {
    //                 // other situations
    //             }
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //         });
    // }


    const formSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Required'),
        password: Yup.string().required('Required')
    })

    const SignIn = async (email, password) => {
        try {
            let res = await Auth.signIn(email, password);
            checkUser()
            navigate('/dashboard')
        } catch (error) {
            setResponseError(error.message)
        }
    }

    async function getUserGroup() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            setCognitoData(user)
            handleUser({ id: user.username })
            return getUserRole(user.username)

        } catch (error) {
            console.log('Error getting user group: ', error);
        }

    }

    const getUserRole = async (id) => {
        try {
            let res = await API.graphql({
                query: getUser,
                variables: {
                    id
                }
            })
            if (res?.data?.getUser?.role) {
                return res?.data?.getUser?.role[0]
            } else if (res?.data?.getUser?.role == null) {
                return 'SCHOOL_ADMIN'
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleGetSchools = async (userId) => {
        try {
            let res = await API.graphql({
                query: listSchoolUsersWithSchools,
                variables: {
                    filter: { userId: { eq: userId } }
                }
            })
            let schools = []
            for (let school of res.data.listSchoolUsers.items) {
                schools.push(school.school);
            }
            handleUser({ schools: schools })
            navigate('/dashboard')

        } catch (error) {
            console.log(error)
        }
    }

    const handlePasswordType = () => {
        if (passwordType === 'password') {
            setPasswordType('text');
        } else {
            setPasswordType('password');
        }
    }

    return (
        <div className="d-flex bg-auth " style={{ display: 'block' }}>
            <div className="container">
                <div className="row vh-100 align-items-center">
                    <div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-md-2 mb-5 mb-md-0 d-none d-lg-block">
                        <div className="text-center">
                            <img src="img/login.svg" alt="..." className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-xl-4 order-md-1 my-5">
                        <h1 className="display-4 text-center mb-5">
                            Sign in
                        </h1>
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            validationSchema={formSchema}
                            onSubmit=
                            {(values) => {
                                SignIn(values.email, values.password)
                            }}
                        >
                            {({ handleChange, handleSubmit, values, errors }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='form-group'>
                                        <label htmlFor='email' className='form-label'>Email address</label>
                                        <input type={'text'} className='form-control' id='email' name={'email'} value={values.email} onChange={handleChange} placeholder="name@address.com" />
                                        <span className='text-danger'>{errors.email}</span>
                                    </div>
                                    <div className='form-group'>
                                        <div className="row">
                                            <div className="col">
                                                <label className="form-label">
                                                    Password
                                                </label>
                                            </div>
                                            <div className="col-auto cursor-pointer" onClick={() => { navigate('/forgot_password') }}>
                                                <p className="form-text small text-muted">
                                                    Forgot password?
                                                </p>
                                            </div>
                                        </div>
                                        <div className="input-group input-group-merge">
                                            <Field className="form-control" type={`${passwordType}`} name='password' value={values.password} placeholder="Enter your password" onChange={handleChange} />
                                            <span className="input-group-text">
                                                <i className={(passwordType === 'password') ? `fe fe-eye` : 'fe fe-eye-off'} onClick={handlePasswordType}></i>
                                            </span>
                                        </div>
                                        <span className='text-danger'>{errors.password || responseError}</span>
                                    </div>
                                    <div className=' form-group'>
                                        <button type='submit' className='btn btn-primary btn-lg w-100' >Sign in</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>





    )
}

export default Login
